html {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  overflow-x: hidden;
}

.glass-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background: linear-gradient(
    108.46deg,
    rgba(255, 255, 255, 0.264) 0%,
    rgba(255, 255, 255, 0.066) 100%
  );
  backdrop-filter: blur(20px);
  color: #fff;
  padding: 50px 25px;
  margin: 10px;
  border-radius: 10px;
}

.btn-main {
  display: flex !important;
  border-radius: 6px !important;
  min-width: 180px !important;
  height: 50px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  text-transform: uppercase !important;
  font-size: 23px !important;
  font-family: "Rubik" !important;
  font-weight: 550 !important;
  background: linear-gradient(to right, #551ef5 20%, #6a57dd 100%);
  transition: all 0.2s ease-in-out !important;
  overflow: hidden;
  /* [theme.breakpoints.up("md")]: {
    marginTop: 0,
    marginRight: 20,
    fontSize: 23,
    height: 50,
    width: 225,
  }, */
}

:hover.btn-main {
  box-shadow: #ffffffbf 0 0 0 3px, transparent 0 0 0 0 !important;
}

/* Thinner Scroll Bar */

::-webkit-scrollbar {
  width: 7px;
  opacity: 0.5;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777db690;
  border-radius: 15px;
  opacity: 0.5;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777db690;
  border-radius: 15px;
  cursor: grab;
  opacity: 0.5;
}

/* Phantom Wallet */
.wallet-adapter-button {
  justify-content: "center";
}

.dance {
  animation: MoveUpDown 1s linear infinite;
  bottom: 100px;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
}

/* Removed Y-Scroll Bar from Main Page */

main.MuiBox-root {
  overflow-x: hidden;
}

/* Shine */
.shine:after {
  content: "";
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 220px;
  position: absolute;
  z-index: 1;
  animation: slide 4s infinite 4s;

  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.invisible .MuiPaper-root {
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: none !important;
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
